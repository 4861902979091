import {
  NORWEGIAN_SPORT_GROUP,
  NORWEGIAN_SPORT_ID,
} from '@ntb-sport/constants';
import { format } from 'date-fns';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export function Index() {
  const router = useRouter();
  const today = format(new Date(), 'yyyy-MM-dd');
  useEffect(() => {
    router.replace(
      `/${NORWEGIAN_SPORT_GROUP.FOOTBALL}/${NORWEGIAN_SPORT_ID.FOOTBALL}/${today}`,
    );
  }, []);

  return null;
}

export default Index;
